/* eslint-disable import/no-anonymous-default-export */
import { api } from "./apiService";

export const getUserList = (token, filter) => api(token).get(`/admin/getUserList?page=${filter.page}&limit=${filter.limit}&search=${filter?.search}`).then(handleResponse);
export const verifyUserEmail = (token, id, data) => api(token).post(`/admin/verifyEmail/${id}`, data).then(handleResponse);
export const updateSettings = (token, id, data) => api(token).post(`/admin/updateSettings/${id}`, data).then(handleResponse);

export const getUserNotifications = (token, filter) => api(token).get(`/admin/notifications/${filter.id}?page=${filter.page}&limit=${filter.limit}`).then(handleResponse);

export const getUserStatistics = (token, id) => api(token).get(`/admin/userStats/${id}`).then(handleResponse);

export const blockUser = (token, data) => api(token).post("/admin/blockUser", data).then(handleResponse);

export const getUserToken = (token, id) => api(token).get(`/admin/userlogin/${id}`).then(handleResponse);

export const religions = (token, opration, data) => {
  switch (opration) {
    case 1:
      return api(token).post("/admin/addReligion", data).then(handleResponse);
      break;
    case 2:
      return api(token).get(`/admin/getReligions?page=${data.page}&limit=${data.limit}`).then(handleResponse);
      break;
    case 3:
      return api(token).put("/admin/updateReligion/" + data._id, data).then(handleResponse);
      break;
    case 4:
      return api(token).delete("/admin/removeReligion/" + data._id).then(handleResponse);
      break;
  }
}

export const addCaste = (token, data) => api(token).post("/admin/addCaste", data).then(handleResponse);
export const getCastes = (token, data) => api(token).get(`/admin/getCastes?page=${data.page}&limit=${data.limit}&search=${data?.search}`).then(handleResponse);
export const updateCaste = (token, data) => api(token).post("/admin/updateCaste", data).then(handleResponse);
export const deleteCaste = (token, id) => api(token).delete(`/admin/removeCaste/${id}`).then(handleResponse);


export const addSubCaste = (token, data) => api(token).post("/admin/addSubCaste", data).then(handleResponse);
export const getSubCastes = (token, filter) => api(token).get(`/admin/getSubCastes?page=${filter.page}&limit=${filter.limit}&search=${filter?.search}`).then(handleResponse);
export const updateSubCaste = (token, data) => api(token).post("/admin/updateSubCaste", data).then(handleResponse);
export const deleteSubCaste = (token, id) => api(token).delete(`/admin/removeSubCaste/${id}`).then(handleResponse);

export const addGothra = (token, data) => api(token).post("/admin/addGotra", data).then(handleResponse);
export const getGothras = (token, filter) => api(token).get(`/admin/getGotras?page=${filter.page}&limit=${filter.limit}&search=${filter?.search}`).then(handleResponse);
export const updateGothra = (token, data) => api(token).post("/admin/updateGotra", data).then(handleResponse);
export const deleteGothra = (token, id) => api(token).delete(`/admin/removeGotra/${id}`).then(handleResponse);


export const getDashboard = (token) => api(token).get("/admin/getDashboard").then(handleResponse);

export const getLifeStyle = (token) => api(token).get("/admin/getLifeStyleOptions").then(handleResponse);

export const updateLifeStyle = (token, data) => api(token).post("/admin/updateLifeStyleOptions", data).then(handleResponse);

export const adminChangePass = (token, id, data) =>
  api(token)
    .put("/admin/changePass/" + id, data)
    .then(handleResponse);


export const createAdmin = (token, data) => api(token).post("/admin/createAdmin", data).then(handleResponse);
export const getAdmin = (token, data) => api(token).get(`/admin/getadmin?page=${data.page}&limit=${data.limit}`).then(handleResponse);
export const updateAdmin = (token, data) => api(token).post("/admin/updateAdmin", data).then(handleResponse);
export const deleteAdmin = (token, id) => api(token).delete(`/admin/removeAdmin/${id}`).then(handleResponse);

export const createLog = (token, data) => api(token).post("/admin/createlog", data).then(handleResponse);
export const getLog = (token, data) => api(token).post(`/admin/log`, data).then(handleResponse);

export const getQueries = (token, data) => api(token).get(`/admin/queries?page=${data.page}&limit=${data.limit}`).then(handleResponse);
export const updateQueries = (token, data) => api(token).post("/admin/updatequeries", data).then(handleResponse);

//user logs
export const getActivityLogs = async (token,data) => await api(token).post("/admin/activity-logs",data).then(handleResponse);
export const deleteUserLogs = (token, data) => api(token).post(`/admin/deletelogs`, data).then(handleResponse);

export const getApiLog = (token, data) => api(token).post(`/admin/getApiLogs`, data).then(handleResponse);
export const deleteApiLogs = (token, data) => api(token).post(`/admin/deleteApiLogs`, data).then(handleResponse);

// ads
export const createOrUpdateAd = (token, data) => api(token).post('/admin/ads', data).then(handleResponse);
export const deleteAds = (token, id) => api(token).delete(`/admin/ads/${id}`).then(handleResponse);
export const getAllAds = (token, data) => api(token).get(`/admin/ads?page=${data.page}&limit=${data.limit}`).then(handleResponse);

export const getDataEdits = (token, data) => api(token).get(`/admin/getDatabaseUpdates?page=${data.page}&limit=${data.limit}`).then(handleResponse);
export const updateDataEdits = (token, data) => api(token).post(`/admin/updateDatabase`,data).then(handleResponse);

const handleResponse = (response) => {
  let data = response.data;
  if (data.code === 404 || response.status !== 200 || (data.code !== 5 && data.code !== 4)) {
    let error = data.error ? data.error : data.message;
    return error;
  }
  return data;
};

export default {
  getUserList,
  blockUser,
  religions,
  getDashboard,
  adminChangePass
};