
// export const CONFIG = {
//     REACT_APP_ENDPOINT: "http://localhost:8000",
//     REACT_APP_UPLOADS: "http://localhost:8000/uploads"
// }

export const CONFIG = {
    REACT_APP_ENDPOINT: window.location.origin,
    REACT_APP_UPLOADS: window.location.origin + "/uploads"
}

// export const CONFIG = {
//     REACT_APP_ENDPOINT: "http://13.234.66.162:3000",
//     REACT_APP_UPLOADS: "http://13.234.66.162:3000/uploads"
// }

// export const CONFIG = {
//     REACT_APP_ENDPOINT: "",
//     REACT_APP_UPLOADS: "uploads"
// }


export const RELATIONS = {
    '5eef5174710aac0934698c85': '', // pita
    '5eebbd0f61ce44163c22a6d2': '5eef5174710aac0934698c85', // mata
    '5eef50d3710aac0934698c7f': '5eef5174710aac0934698c85', // dada
    '5eef54c0710aac0934698cad': '5eef5174710aac0934698c85', // savka pita
    '5eef54d6710aac0934698cae': '5eef54c0710aac0934698cad', // savki mata
    '636a7c0fdd89cb0e2cf22476': '636a7c0fdd89cb0e2cf22475', // kaki
    '636a7c0fdd89cb0e2cf22475': '5eef5174710aac0934698c85', // kaka
    '5eef54ff710aac0934698cb2': '5eebbd0f61ce44163c22a6d2', // nani bahen
    '5eef54f5710aac0934698cb1': '5eebbd0f61ce44163c22a6d2', // nano bhai
    '5eef536b710aac0934698c98': '5eef5380710aac0934698c99', // parnani
    '5eef5290710aac0934698c95': '5eef5285710aac0934698c94', // pardadi
    '5eef5285710aac0934698c94': '5eef50d3710aac0934698c7f', // pardada
    '5eef5380710aac0934698c99': '5eef51cf710aac0934698c8f', // parnana
    '5eef51cf710aac0934698c8f': '5eebbd0f61ce44163c22a6d2', // nana
    '5eef51ed710aac0934698c91': '5eef51cf710aac0934698c8f', // nani
    '5eef51c2710aac0934698c8e': '5eef518c710aac0934698c86', // sasu
    '5eef5131710aac0934698c82': '5eebbd0f61ce44163c22a6d2', // dikari daughter
    '5eebbdd361ce44163c22a6d8': '5eebbd0f61ce44163c22a6d2', // dikaro son
    '5eef54eb710aac0934698cb0': '5eef51ae710aac0934698c8d', // patni
    '5eef53d3710aac0934698c9c': '5eef53ca710aac0934698c9b', // vevai
    '5eef510e710aac0934698c80': '5eef50d3710aac0934698c7f', // dadi
    '5eef5121710aac0934698c81': '5eebbdd361ce44163c22a6d8', // putravadhu
    '5eef514f710aac0934698c83': '5eebbd0f61ce44163c22a6d2',  // moto bhai
    '5eef5160710aac0934698c84': '5eebbd0f61ce44163c22a6d2',  // moti ben
    '5eef53ca710aac0934698c9b': ['5eebbdd361ce44163c22a6d8', '5eef5131710aac0934698c82'],  // vevan
    '5eef518c710aac0934698c86': ['5eebbdd361ce44163c22a6d8', '5eef5131710aac0934698c82'],  // sasra
    '5eef54b2710aac0934698cac': ['5eef5131710aac0934698c82', '5eef5160710aac0934698c84'],  // jamay
    '636a7c0fdd89cb0e2cf22484': ['5eef514f710aac0934698c83', '5eef54f5710aac0934698cb1',],  // bhatrijo
    '636a7c0fdd89cb0e2cf22483': ['5eef514f710aac0934698c83', '5eef54f5710aac0934698cb1',],  // bhatriji
    '636a7c0fdd89cb0e2cf22486': ['5eef5160710aac0934698c84', '5eef54ff710aac0934698cb2'],  // bhaniyo
    '636a7c0fdd89cb0e2cf22485': ['5eef5160710aac0934698c84', '5eef54ff710aac0934698cb2'],  // bhani
    '636a7c0fdd89cb0e2cf22481': ['5eef5160710aac0934698c84', '5eef54ff710aac0934698cb2'],  // jijaji
    '5eef51ae710aac0934698c8d': '',  // pati
    '636a7c0fdd89cb0e2cf2247b': '5eef51ae710aac0934698c8d',  // diyar
    '636a7c0fdd89cb0e2cf2247c': '636a7c0fdd89cb0e2cf2247b',  // derani
    '636a7c0fdd89cb0e2cf2247d': '5eef51ae710aac0934698c8d',  // jeth
    '636a7c0fdd89cb0e2cf2247e': '636a7c0fdd89cb0e2cf2247d',  // jethani
    '636a7c0fdd89cb0e2cf2247f': '5eef54eb710aac0934698cb0',  // salo
    '636a7c0fdd89cb0e2cf22480': '5eef54eb710aac0934698cb0',  // sali
    '636a7c0fdd89cb0e2cf2246d': '5eebbd0f61ce44163c22a6d2',  // mota mama
    '636a7c0fdd89cb0e2cf2246f': '5eebbd0f61ce44163c22a6d2',  // nana mama
    '636a7c0fdd89cb0e2cf22472': '5eebbd0f61ce44163c22a6d2',  // mota masa
    '636a7c0fdd89cb0e2cf22474': '5eebbd0f61ce44163c22a6d2',  // nana masa
    '636a7c0fdd89cb0e2cf2246e': '636a7c0fdd89cb0e2cf2246d',  // mota mami
    '636a7c0fdd89cb0e2cf22470': '636a7c0fdd89cb0e2cf2246f',  // nana mami
    '636a7c0fdd89cb0e2cf22471': '636a7c0fdd89cb0e2cf22471',  // mota masi
    '636a7c0fdd89cb0e2cf22473': '636a7c0fdd89cb0e2cf22473',  // nana masi
    '636a7c0fdd89cb0e2cf22477': '5eef5174710aac0934698c85',  // mota fai
    '636a7c0fdd89cb0e2cf22478': '636a7c0fdd89cb0e2cf22477',  // mota fua
    '636a7c0fdd89cb0e2cf22479': '5eef5174710aac0934698c85',  // nana fai
    '636a7c0fdd89cb0e2cf2247a': '636a7c0fdd89cb0e2cf22479',  // nana fua
    '636a7c0fdd89cb0e2cf22489': '5eef5174710aac0934698c85',  // step brother
    '636a7c0fdd89cb0e2cf2248a': '5eef5174710aac0934698c85',  // step sister
}

export const SINGLE_USER_DISABLED_RELATIONS = [
    "5eef518c710aac0934698c86",
    "5eef5131710aac0934698c82",
    "5eef51c2710aac0934698c8e",
    "5eef54eb710aac0934698cb0",
    "5eef51ae710aac0934698c8d",
    "636a7c0fdd89cb0e2cf2247b",
    "636a7c0fdd89cb0e2cf2247c",
    "636a7c0fdd89cb0e2cf2247d",
    "636a7c0fdd89cb0e2cf2247e",
    "636a7c0fdd89cb0e2cf2247f",
    "636a7c0fdd89cb0e2cf22480",
    "5eef54b2710aac0934698cac",
    "5eef53ca710aac0934698c9b",
    "5eef5121710aac0934698c81",
    "5eebbdd361ce44163c22a6d8", // son
    "63ee3fc88d2cb2ec58989c9d", // grand son
    "63ee3fc88d2cb2ec58989ca0", // grand daughter
    "636a7c0fdd89cb0e2cf22487", // Son/Daughter's Father-in-law
    "636a7c0fdd89cb0e2cf22488", // Son/Daughter's Mother-in-law
];


export const NOT_DUPLICATE_RELATIONS = [
    '5eef5174710aac0934698c85', // pita
    '5eebbd0f61ce44163c22a6d2', // mata
    '5eef50d3710aac0934698c7f', // dada
    '5eef54c0710aac0934698cad', // savka pita
    '5eef54d6710aac0934698cae', // savki mata
    '5eef536b710aac0934698c98', // parnani
    '5eef5290710aac0934698c95', // pardadi
    '5eef5285710aac0934698c94', // pardada
    '5eef5380710aac0934698c99', // parnana
    '5eef51cf710aac0934698c8f', // nana
    '5eef51ed710aac0934698c91', // nani
    '5eef51c2710aac0934698c8e', // sasu
    '5eef54eb710aac0934698cb0', // patni
    '5eef510e710aac0934698c80', // dadi
    '5eef518c710aac0934698c86',  // sasra
    '5eef51ae710aac0934698c8d' // pati
    // wife
    // husband

]

export const PARENT_RELATIONS = {
    "GRAND_GRANDFATHER": "5eef5285710aac0934698c94",
    "GRANDFATHER": "5eef50d3710aac0934698c7f",
    "FATHER": "5eef5174710aac0934698c85",
    "UNCLE": "636a7c0fdd89cb0e2cf22475",
    "ELDER_BROTHER": "5eef514f710aac0934698c83",
    "YOUNGER_BROTHER": "5eef54f5710aac0934698cb1",
    "SON": "5eebbdd361ce44163c22a6d8",
}

export const BROTHER_CHILDREN_LIST = [
    "636a7c0fdd89cb0e2cf22483", // bhatriji
    "636a7c0fdd89cb0e2cf22484", // bhatrijo
]

export const SISTER_CHILDREN_LIST = [
    "636a7c0fdd89cb0e2cf22485", // bhani
    "636a7c0fdd89cb0e2cf22486", // bhaniyo
]

export const IS_MARRIED_MALE = [
    '5eef54eb710aac0934698cb0', // patni
]

export const IS_MARRIED_FEMALE = [
    '5eef51ae710aac0934698c8d', // pati
]

export const CHILDREN_OBJECT = {
    SON: "5eebbdd361ce44163c22a6d8",
    DAUGHTER: "5eef5131710aac0934698c82",
}

export const GRAND_CHILDREN_OBJECT = {
    GRAND_SON: "63ee3fc88d2cb2ec58989c9d",
    GRAND_DAUGHTER: "63ee3fc88d2cb2ec58989ca0",
}

export const GRAND_CHILDREN_LIST = [
    "63ee3fc88d2cb2ec58989c9d",
    "63ee3fc88d2cb2ec58989ca0",
]

export const IS_SINGLE = [
    '5eebbdd361ce44163c22a6d8', // son
    '5eef5131710aac0934698c82', // daughter
    '5eef54eb710aac0934698cb0', // patni
    '5eef51ae710aac0934698c8d', // pati

    // in laws
    "5eef5121710aac0934698c81", // Daughter in law
    "5eef518c710aac0934698c86", // Father in law
    "5eef51c2710aac0934698c8e", // Mother in law
    "5eef54b2710aac0934698cac", // Son in law
    "636a7c0fdd89cb0e2cf2247b", // husband's younger brother
    "636a7c0fdd89cb0e2cf2247c", // husband's younger brother's wife
    "636a7c0fdd89cb0e2cf2247d", // husband's elder brother
    "636a7c0fdd89cb0e2cf2247e", // husband's elder brother's wife
    "636a7c0fdd89cb0e2cf2247f", // wife's brother
    "636a7c0fdd89cb0e2cf22487", // Son/Daughter's Father-in-law
    "636a7c0fdd89cb0e2cf22488", // Son/Daughter's Mother-in-law
]

export const REQUIRED_PARENT = [
    '636a7c0fdd89cb0e2cf22489', // step brother
    '636a7c0fdd89cb0e2cf2248a', // step sister
    "5eef54ff710aac0934698cb2", // nani bahen
    "5eef54f5710aac0934698cb1", // nano bhai
    "5eef514f710aac0934698c83", // moto bhai
    "5eef5160710aac0934698c84", // moti ben
    // "5eebbdd361ce44163c22a6d8", // son
    // "5eef5131710aac0934698c82" // daughter
]

export const CHILDREN_LIST = [
    "5eebbdd361ce44163c22a6d8", // son
    "5eef5131710aac0934698c82" // daughter
]


// relations ids of father side to check whether should spouse dropdown visible
export const FATHER_SIDE_RELATIONS = [
    "636a7c0fdd89cb0e2cf22476", // kaki
    // "5eef510e710aac0934698c80", // dadi // commented because not required to select spouse for grandfather since we're adding only one grandfather
    "5eef5121710aac0934698c81", // putravadhu
    "64008ccab2c14469e2e4c31d", // bhabhi
    // "636a7c0fdd89cb0e2cf22478", // mota fua  //- will require these both ids to filter gender wise spouce
    // "636a7c0fdd89cb0e2cf2247a", // nana fua  //- once we add gender
]

export const FATHER_SIDE_RELATION_OBJECT = {
    MOTHER: "5eebbd0f61ce44163c22a6d2",
    AUNT: "636a7c0fdd89cb0e2cf22476",
    GRANDMOTHER: "5eef510e710aac0934698c80",
    GRAND_GRANDMOTHER: "5eef5290710aac0934698c95",
    DAUGHTERINLAW: "5eef5121710aac0934698c81",
    SISTERINLAW: "64008ccab2c14469e2e4c31d",
}

// relations ids of mother side to check whether should spouse dropdown visible
export const MOTHER_SIDE_RELATIONS = [
    "636a7c0fdd89cb0e2cf2246e", // mota mami
    "636a7c0fdd89cb0e2cf22470", // nana mami
    "636a7c0fdd89cb0e2cf22472", // mota masa
    "636a7c0fdd89cb0e2cf22474", // nana masa
]

export const IN_LAW_RELATIONS = [
    "636a7c0fdd89cb0e2cf2247c",
    "636a7c0fdd89cb0e2cf2247e"
]

export const IN_LAW_SPOUSE_RELATIONS = [
    "636a7c0fdd89cb0e2cf2247d",
    "636a7c0fdd89cb0e2cf2247b"
]

export const PARENTS = [
    '636a7c0fdd89cb0e2cf22475', // kaka
    // '636a7c0fdd89cb0e2cf22476', // kaki
    '5eef54c0710aac0934698cad', // savki pita
    // '5eef54d6710aac0934698cae', // savki mata
    // '5eebbd0f61ce44163c22a6d2', // mata
    '5eef5174710aac0934698c85', // pita
    '636a7c0fdd89cb0e2cf2246d',  // mota mama
    '636a7c0fdd89cb0e2cf2246f',  // nana mama
    '636a7c0fdd89cb0e2cf22472',  // mota masa
    '636a7c0fdd89cb0e2cf22474',  // nana masa
    '636a7c0fdd89cb0e2cf22478',  // mota fua
    '636a7c0fdd89cb0e2cf2247a',  // nana fua
]


export const RELATION_ENABLE = {
    "636a7c0fdd89cb0e2cf2246e": ["636a7c0fdd89cb0e2cf2246d"],
    "636a7c0fdd89cb0e2cf22470": ["636a7c0fdd89cb0e2cf2246f"],
    "636a7c0fdd89cb0e2cf22472": ["636a7c0fdd89cb0e2cf22471"],
    "636a7c0fdd89cb0e2cf22474": ["636a7c0fdd89cb0e2cf22473"],
    "636a7c0fdd89cb0e2cf22478": ["636a7c0fdd89cb0e2cf22477"],
    "636a7c0fdd89cb0e2cf2247a": ["636a7c0fdd89cb0e2cf22479"],
    "636a7c0fdd89cb0e2cf22476": ["636a7c0fdd89cb0e2cf22475"],
    "636a7c0fdd89cb0e2cf22475": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22477": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22479": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22481": ['5eef5160710aac0934698c84', "5eef54ff710aac0934698cb2"],
    "636a7c0fdd89cb0e2cf22483": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "636a7c0fdd89cb0e2cf22484": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "636a7c0fdd89cb0e2cf22485": ["5eef5160710aac0934698c84", "5eef54ff710aac0934698cb2"],
    "636a7c0fdd89cb0e2cf22486": ["5eef5160710aac0934698c84", "5eef54ff710aac0934698cb2"],
    "66a08915afc2a5ce5791874f": ["5eef51ae710aac0934698c8d"],
    "664307f4c389452f99dbe589": ["5eef50d3710aac0934698c7f"],
    "66430819c389452f99dbe58a": ["5eef50d3710aac0934698c7f"],
    "66430838c389452f99dbe58c": ["664307f4c389452f99dbe589"],
    "6643082bc389452f99dbe58b": ["66430819c389452f99dbe58a"],
    "64008ccab2c14469e2e4c31d": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "636a7c0fdd89cb0e2cf2246d": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf2246f": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf22471": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf22473": ["5eef51cf710aac0934698c8f"],
    "6643073dc389452f99dbe585": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "66430761c389452f99dbe586": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "664307a0c389452f99dbe587": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "664307c1c389452f99dbe588": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "5eebbdd361ce44163c22a6d8": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "5eef5131710aac0934698c82": ["5eef51ae710aac0934698c8d", "5eef54eb710aac0934698cb0"],
    "5eef51ed710aac0934698c91": ["5eef51cf710aac0934698c8f"],
    "5eef536b710aac0934698c98": ["5eef5380710aac0934698c99"],
    "5eef5290710aac0934698c95": ["5eef5285710aac0934698c94"],
    "5eef51cf710aac0934698c8f": ["5eebbd0f61ce44163c22a6d2", "5eef5380710aac0934698c99"],
    "5eef514f710aac0934698c83": ["5eef5174710aac0934698c85"],
    "5eef5160710aac0934698c84": ["5eef5174710aac0934698c85"],
    "5eef54f5710aac0934698cb1": ["5eef5174710aac0934698c85"],
    "5eef54ff710aac0934698cb2": ["5eef5174710aac0934698c85"],

}


export const CAN_BE_SPOUSE = {
    "636a7c0fdd89cb0e2cf22476": ["636a7c0fdd89cb0e2cf22475"],
    "5eef510e710aac0934698c80": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22477": ["636a7c0fdd89cb0e2cf22478"],
    "636a7c0fdd89cb0e2cf22479": ["636a7c0fdd89cb0e2cf2247a"],
    "5eebbd0f61ce44163c22a6d2": ["5eef5174710aac0934698c85", "5eef54c0710aac0934698cad"],
    "5eef51ed710aac0934698c91": ["5eef50d3710aac0934698c7f"],
    "5eef5131710aac0934698c82": ["5eef54b2710aac0934698cac"],
    "5eef536b710aac0934698c98": ["5eef5380710aac0934698c99"],
    "5eef54d6710aac0934698cae": ["5eef5174710aac0934698c85", "5eef54c0710aac0934698cad"],
    "636a7c0fdd89cb0e2cf2246e": ["636a7c0fdd89cb0e2cf2246d"],
    "636a7c0fdd89cb0e2cf22470": ["636a7c0fdd89cb0e2cf2246f"],
    "636a7c0fdd89cb0e2cf22471": ["636a7c0fdd89cb0e2cf22472"],
    "636a7c0fdd89cb0e2cf22473": ["636a7c0fdd89cb0e2cf22474"],
    "5eef51c2710aac0934698c8e": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf2247c": ["636a7c0fdd89cb0e2cf2247b"],
    "636a7c0fdd89cb0e2cf2247e": ["636a7c0fdd89cb0e2cf2247d"],
    "636a7c0fdd89cb0e2cf22488": ["636a7c0fdd89cb0e2cf22487"],
    "64008ccab2c14469e2e4c31d": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "5eef5160710aac0934698c84": ["636a7c0fdd89cb0e2cf22481"],
    "5eef54ff710aac0934698cb2": ["636a7c0fdd89cb0e2cf22481"],
    "5eef5290710aac0934698c95": ["5eef5285710aac0934698c94"],
    "66430761c389452f99dbe586": ["5eef54b2710aac0934698cac"],
    "664307a0c389452f99dbe587": ["5eef54b2710aac0934698cac"],
    "6643082bc389452f99dbe58b": ["66430819c389452f99dbe58a"],
    "66430838c389452f99dbe58c": ["664307f4c389452f99dbe589"]
}

export const CAN_BE_PARENTS = {
    "5eebbdd361ce44163c22a6d8": ["65dc7cb2d189e84ba2c23112", "5eef51ae710aac0934698c8d"],
    "6643073dc389452f99dbe585": ["65dc7cb2d189e84ba2c23112", "5eef51ae710aac0934698c8d"],
    "66430761c389452f99dbe586": ["65dc7cb2d189e84ba2c23112", "5eef51ae710aac0934698c8d"],
    "664307a0c389452f99dbe587": ["65dc7cb2d189e84ba2c23112", "5eef51ae710aac0934698c8d"],
    "664307c1c389452f99dbe588": ["65dc7cb2d189e84ba2c23112", "5eef51ae710aac0934698c8d"],
    "5eef50d3710aac0934698c7f": ["5eef5285710aac0934698c94"],
    "5eef5121710aac0934698c81": ["636a7c0fdd89cb0e2cf22487"],
    "5eef5131710aac0934698c82": ["65dc7cb2d189e84ba2c23112"],
    "5eef5174710aac0934698c85": ["5eef50d3710aac0934698c7f"],
    "63ee3fc88d2cb2ec58989c9d": ["5eebbdd361ce44163c22a6d8", "636a7c0fdd89cb0e2cf22481", "636a7c0fdd89cb0e2cf22484", "636a7c0fdd89cb0e2cf22486"],
    "63ee3fc88d2cb2ec58989ca0": ["5eebbdd361ce44163c22a6d8", "636a7c0fdd89cb0e2cf22481", "636a7c0fdd89cb0e2cf22484", "636a7c0fdd89cb0e2cf22486"],
    "636a7c0fdd89cb0e2cf22475": ["5eef50d3710aac0934698c7f"],
    "664307f4c389452f99dbe589": ["5eef50d3710aac0934698c7f"],
    "66430819c389452f99dbe58a": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22477": ["5eef50d3710aac0934698c7f"],
    "636a7c0fdd89cb0e2cf22479": ["5eef50d3710aac0934698c7f"],
    "5eebbd0f61ce44163c22a6d2": ["5eef51cf710aac0934698c8f"],
    "5eef51cf710aac0934698c8f": ["5eef5380710aac0934698c99"],
    "636a7c0fdd89cb0e2cf2246d": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf2246f": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf22471": ["5eef51cf710aac0934698c8f"],
    "636a7c0fdd89cb0e2cf22473": ["5eef51cf710aac0934698c8f"],
    "5eef51ae710aac0934698c8d": ["5eef518c710aac0934698c86"],
    "5eef54b2710aac0934698cac": ["636a7c0fdd89cb0e2cf22487"],
    "5eef54eb710aac0934698cb0": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf2247b": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf2247d": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf2247f": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf22480": ["5eef518c710aac0934698c86"],
    "636a7c0fdd89cb0e2cf22483": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "636a7c0fdd89cb0e2cf22484": ["5eef514f710aac0934698c83", "5eef54f5710aac0934698cb1"],
    "636a7c0fdd89cb0e2cf22485": ["636a7c0fdd89cb0e2cf22481"],
    "636a7c0fdd89cb0e2cf22486": ["636a7c0fdd89cb0e2cf22481"],
    "5eef514f710aac0934698c83": ["66430819c389452f99dbe58a", "664307f4c389452f99dbe589", "5eef5174710aac0934698c85", "5eef54c0710aac0934698cad", "636a7c0fdd89cb0e2cf22475", "636a7c0fdd89cb0e2cf22478", "636a7c0fdd89cb0e2cf2247a", "636a7c0fdd89cb0e2cf2246d", "636a7c0fdd89cb0e2cf2246f", "636a7c0fdd89cb0e2cf22472", "636a7c0fdd89cb0e2cf22474"],
    "5eef5160710aac0934698c84": ["66430819c389452f99dbe58a", "664307f4c389452f99dbe589", "5eef5174710aac0934698c85", "5eef54c0710aac0934698cad", "636a7c0fdd89cb0e2cf22475", "636a7c0fdd89cb0e2cf22478", "636a7c0fdd89cb0e2cf2247a", "636a7c0fdd89cb0e2cf2246d", "636a7c0fdd89cb0e2cf2246f", "636a7c0fdd89cb0e2cf22472", "636a7c0fdd89cb0e2cf22474"],
    "5eef54f5710aac0934698cb1": ["66430819c389452f99dbe58a", "664307f4c389452f99dbe589", "5eef5174710aac0934698c85", "5eef54c0710aac0934698cad", "636a7c0fdd89cb0e2cf22475", "636a7c0fdd89cb0e2cf22478", "636a7c0fdd89cb0e2cf2247a", "636a7c0fdd89cb0e2cf2246d", "636a7c0fdd89cb0e2cf2246f", "636a7c0fdd89cb0e2cf22472", "636a7c0fdd89cb0e2cf22474"],
    "5eef54ff710aac0934698cb2": ["66430819c389452f99dbe58a", "664307f4c389452f99dbe589", "5eef5174710aac0934698c85", "5eef54c0710aac0934698cad", "636a7c0fdd89cb0e2cf22475", "636a7c0fdd89cb0e2cf22478", "636a7c0fdd89cb0e2cf2247a", "636a7c0fdd89cb0e2cf2246d", "636a7c0fdd89cb0e2cf2246f", "636a7c0fdd89cb0e2cf22472", "636a7c0fdd89cb0e2cf22474"]
}




// export const TREE = {
//     level1: ['5eef5285710aac0934698c94', '5eef5290710aac0934698c95'],
//     level2: ['5eef50d3710aac0934698c7f', '5eef510e710aac0934698c80'],
//     level3: [
//         '5eef5174710aac0934698c85',
//         '5eebbd0f61ce44163c22a6d2',
//         '636a7c0fdd89cb0e2cf22475',
//         '636a7c0fdd89cb0e2cf22476',
//         '636a7c0fdd89cb0e2cf22477',
//         '636a7c0fdd89cb0e2cf22478',
//         '636a7c0fdd89cb0e2cf22479',
//         '636a7c0fdd89cb0e2cf2247a']
// }