import dataConstant from "../constants/data_constants";

const dataState = {
  castes: [],
  countries: [],
  motherToungues: [],
  religions: [],
  cities: [],
  states: [],
  gothras: [],
  subcastes: [],
  relations: [],
  ads: [],
  lifeStyle: {},
  history: []
};

function data(state = dataState, action) {
  switch (action.type) {
    case dataConstant.SET_DATA:
      return {
        ...state,
        castes: action.payload._castes,
        religions: action.payload._religions,
        motherToungues: action.payload._mothertoungues,
        relations: action.payload._relations,
        ads: action.payload._ads,
        lifeStyle: action.payload._lifeStyle,
        countries: action.payload._countries
        // states: action.payload.states,
        // cities: action.payload.cities,
        // subcastes: action.payload.subcastes,
        // gothras: action.payload.gothras,
      };
    case dataConstant.SET_RELIGIONS:
      return {
        ...state,
        religions: action.payload,
      };
    case dataConstant.SET_HISTORY:
      if (state.history.length == 0 || state.history[state.history.length - 1] != action.payload) {
        return {
          ...state,
          history: [...state.history, action.payload]
        }
      }else{
        return state
      }
    case dataConstant.BACK_HISTORY:
      return {
        ...state,
        history: action.payload
      }
    default:
      return state;
  }
}

export default data;
