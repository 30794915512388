import { api } from "./apiService";

export const registerUser = (user) => api().post("/user/register", user).then(handleResponse);

export const verifyUser = (token) => api(token).get("/user/verify").then(handleResponse);

export const getUser = (token, id, page = false) =>
  api(token)
    .get("/user/get/" + id + "?page=" + page)
    .then(handleResponse);

export const getUserDashboard = (token) =>
  api(token)
    .get("/user/get-dashboard-data")
    .then(handleResponse);

export const getHoroscope = (token, id, data) =>
  api(token)
    .post("/user/get/horoscope/" + id, data)
    .then(handleResponse);

export const getPreferences = (token, id) =>
  api(token)
    .get("/user/get/preference/" + id)
    .then(handleResponse);

export const updateProfilePicture = (token, data) => api(token).post("/user/update/updateProfilePicture", data).then(handleResponse);

export const deleteImage = (token, url) => api(token).delete(`/user/deleteImage?url=${url}`).then(handleResponse);

export const uploadPictures = (token, data) => api(token).post("/user/update/uploadPictures", data).then(handleResponse);

export const setProfilePicture = (token, data) => api(token).post("/user/setProfilePicture", data).then(handleResponse);

export const updateBasicDetails = (token, id, data) =>
  api(token)
    .put("/user/update/basicDetails/" + id, data)
    .then(handleResponse);

export const updateContactDetails = (token, id, data) =>
  api(token)
    .put("/user/update/contactDetails/" + id, data)
    .then(handleResponse);


export const updateHoroscopeBlockList = (token, data) =>
  api(token)
    .put("/user/update/horoscope/block", data)
    .then(handleResponse);

export const updateEmail = (token, data) =>
  api(token)
    .put("/user/update/email", data)
    .then(handleResponse);

export const updateLocationDetails = (token, id, data) =>
  api(token)
    .put("/user/update/locationDetails/" + id, data)
    .then(handleResponse);

export const updateHoroscopeDetails = (token, id, data) =>
  api(token)
    .put("/user/update/horoscope/" + id, data)
    .then(handleResponse);

export const updateReligionDetails = (token, id, data) =>
  api(token)
    .put("/user/update/religionDetails/" + id, data)
    .then(handleResponse);

export const updateProfessionalDetails = (token, id, data) =>
  api(token)
    .put("/user/update/professionalDetails/" + id, data)
    .then(handleResponse);

export const updateAboutFamily = (token, id, data) =>
  api(token)
    .put("/user/update/aboutFamily/" + id, data)
    .then(handleResponse);

export const updateLifeStyleDetails = (token, id, data) =>
  api(token)
    .put("/user/update/lifeStyleDetails/" + id, data)
    .then(handleResponse);

export const updateFamilyMember = (token, id, data) =>
  api(token)
    .put("/user/update/familyMembers/" + id, data)
    .then(handleResponse);

export const updateFamilyDetails = (token, id, data) =>
  api(token)
    .put("/user/update/familyDetails/" + id, data)
    .then(handleResponse);

export const userPreferences = (token, id, data) =>
  api(token)
    .put("/user/userPreferences/" + id, data)
    .then(handleResponse);

export const updateSetting = (token, id, data) =>
  api(token)
    .put("/user/updateSetting/" + id, data)
    .then(handleResponse);

export const updateUniqueId = (token, id, data) =>
  api(token)
    .put("/user/update/updateUniqueId/" + id, data)
    .then(handleResponse);

export const changePass = (token, id, data) =>
  api(token)
    .put("/user/changePass/" + id, data)
    .then(handleResponse);

export const deactivateProfile = (token, data) =>
  api(token)
    .post("/user/deactivate/", data)
    .then(handleResponse);

export const deleteUserProfile = (token, data) => {
  api(token)
    .post("/user/delete/", data)
    .then(handleResponse);
}

export const getProfilePercentage = (token, id) =>
  api(token)
    .get("/user/getProfilePercentage/" + id)
    .then(handleResponse);

export const getNewUser = (token, id) =>
  api(token)
    .get("/user/getNewUser/" + id)
    .then(handleResponse);

export const getSimilarProfile = (token, id) => api(token).get("/user/getSimilarProfile/" + id).then(handleResponse);

export const getViewUserData = (token, id) =>
  api(token)
    .get("/user/getViewUserData/" + id)
    .then(handleResponse);

export const sendRequest = (token, data) => api(token).post("/user/sendRequest", data).then(handleResponse);

export const getRequests = (token, data) => api(token).post("/user/getRequests", data).then(handleResponse);

export const sendResponse = (token, data) => api(token).post("/user/sendResponse", data).then(handleResponse);

export const getNotifications = (token, data) =>
  api(token)
    .get(`/user/notifications/${data.type}?page=${data.page}&limit=${data.limit}`)
    .then((response) => response.data)
    .catch((error) => console.error("Error: Error while getting notifications.", error));

export const readNotification = (token, id) => api(token).get(`/user/readNotification/${id}`).then(handleResponse);


export const showFamilyMembers = (token, requestId) =>
  api(token)
    .get(`/user/showMembers/${requestId}`, {})
    .then((response) => response.data)
    .catch((error) => console.error("Error: Error while getting family members of the request requestId", error));

export const recommendFamilyMembers = (token, id) =>
  api(token)
    .get(`/member/memberRecommendation/${id}`)
    .then(handleResponse)

export const addRecommendMember = (token, data) =>
  api(token)
    .post(`/member/add/recommendedMember`, data)
    .then(handleResponse)

export const familyMergeRequest = (token, payload) =>
  api(token)
    .post("/user/member/merge/request", payload)
    .then((response) => response?.data)
    .catch((error) => console.error("Error: Error while calling merge request API.", error));

export const verifyMembersMerge = (token, requestId) =>
  api(token)
    .post("/user/member/merge/verify", { request: requestId })
    .then((response) => response?.data)
    .catch((error) => console.error("Error: Error while call verify member API.", error));

export const membersMergeApprove = (token, requestId) =>
  api(token)
    .post("/user/member/merge/approve", { request: requestId })
    .then((response) => response?.data)
    .catch((error) => console.error("Error: Error while call members merge approve API.", error));

export const blockUser = (token, data) => api(token).post("/user/blockUser", data).then(handleResponse);

export const rateUser = (token, data) => api(token).post("/user/rateUser", data).then(handleResponse);

export const commentUser = (token, data) => api(token).post("/user/commentUser", data).then(handleResponse);

export const deleteUserComment = (token, id) => api(token).delete(`/user/deleteComment/${id}`).then(handleResponse);

export const matchUser = (token, data, id) => api(token).post(`/match/matchAccount/${id}`, data).then(handleResponse);

export const userTreeView = (token, data) => api(token).post(`/user/getUserTree/${data.id}?tree=${data.type}`, data).then(handleResponse);

export const getmatchUser = (token, data) => api(token).post("/match/getMatchedRequest", data).then(handleResponse);

export const updatematchUser = (token, data) => api(token).put("/match/updateMatchRequest", data).then(handleResponse);

export const updateUserTreeView = (token, data) => api(token).post("/user/update/request/viewTree", data).then(handleResponse);

export const sendTreeView = (token, data) => api(token).post("/user/request/viewTree", data).then(handleResponse);

export const getTreeViewRequest = (token, data) => api(token).post("/user/getTreeRequest", data).then(handleResponse);

export const shortlistUser = (token, id) => api(token).post(`/match/shortlistAccount/${id}`).then(handleResponse);


export const getRelationOption = (token, id) => {
  return api(token)
    .get("/user/getRelationOption/" + id)
    .then(handleResponse);
};

export const messageUser = (token, data) => api(token).post("/user/sendMessage", data).then(handleResponse);
export const userRateUser = (token, data) => api(token).post("/user/rateUser", data).then(handleResponse);

export const getComments = (token, id) =>
  api(token)
    .get("/user/getComments/" + id)
    .then(handleResponse);

export const searchUsers = (token, data) => api(token).post("/user/searchUsers/", data).then(handleResponse);

export const getViewers = (token, data) => api(token).post(`/user/getViewers`, data).then(handleResponse);

export const getMatches = (token, data) => api(token).post(`/user/getMatches`, data).then(handleResponse);

export const getShortlist = (token, data) => api(token).post(`/match/getShortlist`, data).then(handleResponse);

export const getMessages = (token, id, skip) => api(token).get(`/user/getMessages/${id}?skip=${skip}`).then(handleResponse);

export const getChatUsers = (token, search) => api(token).get(`/user/getChatUsers?search=${search}`).then(handleResponse);

export const getUsersByMobile = (token, mobile) => api(token).get(`/user/getUsersByMobile?mobile=${mobile}`).then(handleResponse);

export const getBlockedUsers = (token, data) => api(token).post(`/user/getBlockedUsers`, data).then(handleResponse);

export const recordUserLogs = (token, data) => api(token).post(`/user/registerLog`, data).then(handleResponse);

export const sendEmail = (token, data) => api(token).post(`/user/sendMail`, data).then(handleResponse);

export const getTreeData = (token, type) => api(token).get(`/user/getTreeData?tree=${type}`).then(handleResponse);

export const getFilterForMatch = (token) => api(token).get(`/match/getMatchFilters`).then(handleResponse);

export const getFilteredMatch = (token, filter, id) => api(token).post(`/match/getNewMatch/${id}`, filter).then(handleResponse);

export const getMatchChildren = (token, id) => api(token).post(`/match/getChildrenMatches/${id}`).then(handleResponse);

export const getPreferedYouMatches = (token, data) => api(token).get(`/match/getPreferedYou?page=${data?.page}&limit=${data?.limit}`).then(handleResponse);

export const getMember = (token) => api(token).get(`/member`).then(handleResponse);

export const createMember = (token, data) => api(token).post(`/member`, data).then(handleResponse);

export const updateMemberFromRelations = (token, data) => api(token).post(`/member/setMemberFrom`, data).then(handleResponse);

export const deleteFamilyMember = (token, id) => api(token).delete(`/member/${id}`).then(handleResponse);

const handleResponse = (response) => {
  let data = response.data;
  if (data.status === 404 || response.status !== 200 || (data.code !== 5 && data.code !== 4)) {
    let error = data.error ? data.error : data.message;
    return error;
  }
  return data;
};
// export default { registerUser, getUser };
